<ih-admin-base editorTitle="Products" svgIcon="cart-outline">
  <!-- Hide save button -->
  <div class="button-controls" button-controls>
    <button mat-button [disabled]="!stripeConfig()?.chargesEnabled" (click)="toggleShowArchived()">
      {{ archived() ? 'Hide archived' : 'Show archived' }}
    </button>
    <!-- <button
      mat-flat-button
      color="primary"
      [disabled]="!stripeConfig()?.chargesEnabled"
      class="post-add-product-button"
      (click)="showCreateDialog()"
    >
      Add product
    </button> -->
  </div>
  @if (!stripeConfig()?.chargesEnabled) {
    <div class="stripe-disabled-container">
      <ih-stripe-status [stripeConfig]="stripeConfig() ?? null"></ih-stripe-status>
    </div>
  } @else {
    <div class="product-list-container" *ngIf="products$ | async as products; else loading">
      <mat-action-list>
        @for (product of products; let last = $last; track product.productId) {
          <button mat-list-item (click)="edit(product.productId!)">
            <div class="product-list-item fx-layout-row fx-layout-align-start-start">
              <ih-image
                aria-label="product image"
                class="product-image"
                *ngIf="product.image"
                [imageInfo]="product.image"
              ></ih-image>
              <!-- None card -->
              @if (!product.image) {
                <div class="product-image product-no-image fx-layout-align-center-center">
                  <div class="product-no-image-container">
                    <svg class="product-no-image-icon" viewBox="0 0 24 24">
                      <path
                        d="M9.2 6L7.2 4H20C21.11 4 22 4.89 22 6V18C22 18.24 21.96 18.47 21.88 18.68L20 16.8V6H9.2M22.11 21.46L20.84 22.73L18.11 20H4C2.9 20 2 19.11 2 18V6C2 5.42 2.25 4.9 2.65 4.54L1.11 3L2.39 1.73L6.66 6L18.65 18H18.66L20.57 19.91L20.56 19.91L22.11 21.46M16.11 18L4.11 6H4V18H16.11Z"
                      />
                    </svg>
                  </div>
                </div>
              }
              <div class="product-info-container fx-flex">
                <div class="product-name">{{ product.name }}</div>
                <div class="product-info">
                  <div class="product-description">{{ product.description }}</div>
                  <div class="product-details fx-layout-row fx-layout-wrap fx-gap-8">
                    @if (product.prices.length > 0) {
                      <fieldset class="product-prices-container">
                        <legend class="product-prices-label">Prices</legend>
                        @for (
                          price of product.prices | slice: 0 : (product.showMorePrices ? undefined : 3);
                          track price.productPriceId
                        ) {
                          <div class="product-price">
                            <ih-price-interval
                              [amount]="price.amount"
                              [currency]="price.currency.code"
                              [interval]="price.interval"
                              [intervalCount]="price.intervalCount"
                              [taxBehavior]="price.taxBehavior"
                            ></ih-price-interval>
                          </div>
                        }
                        @if (product.prices.length > 3) {
                          <a class="product-show-more-prices" (click)="toggleShowMorePrices($event, product)">
                            {{ product.showMorePrices ? 'Show less' : 'Show more' }}
                          </a>
                        }
                      </fieldset>
                    } @else {
                      <div class="product-no-prices">No prices</div>
                    }
                    @if (product.channels.length > 0) {
                      <fieldset class="product-channels-container fx-layout-row fx-layout-wrap fx-gap-8">
                        <legend class="product-channels-label">Channels</legend>
                        @for (
                          channel of product.channels | slice: 0 : (product.showMoreChannels ? undefined : 3);
                          track channel.channelId
                        ) {
                          <div class="product-channel-pill fx-layout-row fx-layout-align-start-center">
                            <ih-channel-icon class="product-channel-icon" [channel]="channel"></ih-channel-icon>
                            <span class="product-channel-name">{{ channel.name }}</span>
                          </div>
                        }
                        @if (product.channels.length > 3) {
                          <a class="product-show-more-channels" (click)="toggleShowMoreChannels($event, product)">
                            {{ product.showMoreChannels ? 'Show less' : 'Show more' }}
                          </a>
                        }
                      </fieldset>
                    }
                  </div>
                </div>
              </div>

              <div class="action-buttons" matListItemMeta>
                <button aria-label="edit product" mat-icon-button matTooltip="Edit">
                  <mat-icon svgIcon="pencil-outline"></mat-icon>
                </button>
                @if (!product.archived) {
                  <button
                    aria-label="archive product"
                    mat-icon-button
                    matTooltip="Archive"
                    (click)="archive($event, product)"
                  >
                    <mat-icon svgIcon="archive-outline"></mat-icon>
                  </button>
                } @else {
                  <button
                    aria-label="restore product"
                    mat-icon-button
                    matTooltip="Restore"
                    (click)="restore($event, product)"
                  >
                    <mat-icon svgIcon="restore"></mat-icon>
                  </button>
                }
              </div>
            </div>
          </button>
        }
      </mat-action-list>
      <div *ngIf="products.length === 0">You currently have no products.</div>
    </div>
  }
</ih-admin-base>

<ng-template #loading>
  <div class="loading-container fx-layout-row fx-layout-align-center">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
