import { AsyncPipe, CurrencyPipe, NgFor, NgIf, SlicePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { AdminBaseComponent } from '@ih/admin-base';
import { fadeInOutAndHeightM3 } from '@ih/animations';
import { ChannelIconComponent } from '@ih/channel-icon';
import { RecurringInterval } from '@ih/enums';
import { FilterChipsComponent } from '@ih/filter-chips';
import { ImageComponent } from '@ih/image';
import { InfoPanelComponent } from '@ih/info-panel';
import { BaseClientConfig, Channel, ImageInfo, MediaCropImage, ProductListItem } from '@ih/interfaces';
import { PriceIntervalComponent } from '@ih/price-interval';
import { ChannelService, ConfigService, LazySnackBarService } from '@ih/services';
import { ProductEditDialogComponent, ProductEditDialogService, StripeStatusComponent } from '@ih/shop';
import { Subject, merge, of } from 'rxjs';
import { catchError, map, shareReplay, startWith, switchMap } from 'rxjs/operators';

interface ProductListItemWithChannels extends ProductListItem<ImageInfo> {
  channels: Channel[];
  showMorePrices: boolean;
  showMoreChannels: boolean;
  archived: boolean;
}

@Component({
  standalone: true,
  imports: [
    AsyncPipe,
    CurrencyPipe,
    NgFor,
    NgIf,
    RouterLink,
    SlicePipe,

    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatTooltipModule,

    AdminBaseComponent,
    ChannelIconComponent,
    FilterChipsComponent,
    ImageComponent,
    InfoPanelComponent,
    PriceIntervalComponent,
    StripeStatusComponent
  ],
  selector: 'ih-product-admin',
  templateUrl: './product-admin.component.html',
  styleUrls: ['./product-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOutAndHeightM3]
})
export class ProductAdminComponent {
  private http = inject(HttpClient);
  private matDialog = inject(MatDialog);
  private config = inject(ConfigService) as ConfigService<BaseClientConfig>;
  private snackbar = inject(LazySnackBarService);
  private channelService = inject(ChannelService);
  private productEditDialog = inject(ProductEditDialogService);

  private refresh$ = new Subject<void>();

  stripeConfig = toSignal(this.config.config$.pipe(map((config) => config.stripe)));

  RecurringInterval = RecurringInterval;

  archived = signal(false);

  currency$ = this.config.config$.pipe(map((config) => config.currency));

  products$ = merge(this.refresh$, this.channelService.channels$).pipe(
    startWith({}),
    switchMap(() => this.channelService.channels$),
    switchMap((channels) =>
      this.http
        .get<ProductListItem<ImageInfo>[]>('/api/products', {
          params: {
            archived: this.archived().toString()
          }
        })
        .pipe(
          map(
            (products) =>
              products.map((product) => ({
                ...product,
                channels: product.channelIds.map((channelId) => channels[channelId]).filter((c) => c),
                // convert prices from cents to dollars
                prices: product.prices
                  .filter((p) => !p.archived)
                  .map((price) => ({
                    ...price,
                    amount: price.amount / 100
                  }))
              })) as ProductListItemWithChannels[]
          )
        )
    ),
    shareReplay(1)
  );

  getIntervalText(interval: RecurringInterval): string {
    if (interval === RecurringInterval.Never) {
      return 'one-time';
    }
    return RecurringInterval[interval].toLowerCase();
  }

  showCreateDialog(): void {
    this.matDialog
      .open(ProductEditDialogComponent, {
        panelClass: 'product-edit-dialog',
        maxWidth: undefined
      })
      .afterClosed()
      .pipe(
        switchMap((product: ProductListItem<MediaCropImage>) => {
          if (product) {
            return this.http
              .post('/api/products', product, {
                params: {
                  archived: this.archived().toString()
                }
              })
              .pipe(map(() => product));
          }

          return of(null);
        }),
        catchError((err, caught) => {
          this.snackbar.open('Error creating product', 'RETRY').then((snackbarRef) =>
            snackbarRef
              .onAction()
              .pipe(switchMap(() => caught))
              .subscribe()
          );
          throw err;
        })
      )
      .subscribe((product) => {
        if (!product) {
          return;
        }

        this.snackbar.open('Product created', 'OK', { duration: 3000 });
        this.refresh$.next();
      });
  }

  edit(productId: number): void {
    this.http
      .get<ProductListItem<MediaCropImage>>(`/api/products/${productId}`)
      .pipe(
        // product editor is expecting prices in dollars
        map((product) => ({
          ...product,
          prices: product.prices.map((price) => ({
            ...price,
            amount: price.amount / 100
          }))
        })),
        switchMap((product) => this.productEditDialog.open(product)),
        switchMap((product: ProductListItem<MediaCropImage>) => {
          if (product) {
            return this.http.put(`/api/products/${product.productId}`, product).pipe(map(() => product));
          }

          return of(null);
        }),
        catchError((err, caught) => {
          this.snackbar.open('Error updating product', 'RETRY').then((snackbarRef) =>
            snackbarRef
              .onAction()
              .pipe(switchMap(() => caught))
              .subscribe()
          );
          throw err;
        })
      )
      .subscribe((product) => {
        if (!product) {
          return;
        }

        this.snackbar.open('Product updated', 'OK', { duration: 3000 });
        this.refresh$.next();
      });
  }

  restore(event: Event, product: ProductListItem<MediaCropImage>): void {
    event.stopPropagation();

    this.http
      .put(`/api/products/${product.productId}`, { ...product, archived: false })
      .pipe(
        catchError((err, caught) => {
          this.snackbar.open('Error restoring product', 'RETRY').then((snackbarRef) =>
            snackbarRef
              .onAction()
              .pipe(switchMap(() => caught))
              .subscribe()
          );
          throw err;
        })
      )
      .subscribe(() => {
        this.snackbar.open('Product restored', 'OK', { duration: 3000 });
        this.refresh$.next();
      });
  }

  archive(event: Event, product: ProductListItem<MediaCropImage>): void {
    event.stopPropagation();

    this.http
      .delete(`/api/products/${product.productId}`)
      .pipe(
        catchError((err, caught) => {
          this.snackbar.open('Error archiving product', 'RETRY').then((snackbarRef) =>
            snackbarRef
              .onAction()
              .pipe(switchMap(() => caught))
              .subscribe()
          );
          throw err;
        })
      )
      .subscribe(() => {
        this.snackbar.open('Product archived', 'OK', { duration: 3000 });
        this.refresh$.next();
      });
  }

  refresh(): void {
    this.refresh$.next();
  }

  toggleShowArchived(): void {
    this.archived.update((archived) => !archived);
    this.refresh$.next();
  }

  toggleShowMoreChannels(event: Event, product: ProductListItemWithChannels): void {
    event.stopPropagation();
    product.showMoreChannels = !product.showMoreChannels;
  }

  toggleShowMorePrices(event: Event, product: ProductListItemWithChannels): void {
    event.stopPropagation();
    product.showMorePrices = !product.showMorePrices;
  }

  trackByProductId(index: number, product: ProductListItem<MediaCropImage>): number | undefined {
    return product.productId;
  }
}
